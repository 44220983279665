import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import { Context } from '../App'

function ButtonSet({tag}) {
    const [getBtags, setBtags] = useContext(Context) // {tag and true or false}
    const [state, setState] = useState(false)
    function handleClick(state){
        console.log("setting " + tag + " to " + state)
        setBtags((prevState) => ({
            ...prevState,
            [tag]: state,
          }));
    }

    useEffect(() => {
        setState(getBtags[tag])
    }, [])
    useEffect(() => {
        console.log("updoot " + getBtags[tag])
        setState(getBtags[tag])
    }, [getBtags[tag]])
  return (
    <div className='p-4'>

        
        
        <button type="button" className={`
         hover:bg-red-600 ${state === false? 'bg-red-600 ': 'bg-red-950'}
         p-3 rounded-lg m-2 px-12
        `}onClick={() => handleClick(false)}> No</button>
 ||
         
 <button type="button" className={`
        hover:bg-green-600 ${state === true? 'bg-green-600 ': 'bg-green-950'}
        
        p-3 rounded-lg m-2 px-12
        `} onClick={() => handleClick(true)}>Yes </button> 

    </div>
    
  )
}

export default ButtonSet