import logo from './logo.svg';
import './App.css';
import ValidationForm from './modules/ValidationForm';
import { useState, createContext } from "react";
import React from 'react';
export const Context = React.createContext();

function App() {

  const [getBtags, setBtags] = useState({
    b1: false,
    b2: false,
    b3:false
  });

  return (
    <div className="App flex justify-center">
        <Context.Provider value={[getBtags,setBtags]}>
        
        <div>
          <div className=''>

          </div>

          <div className='flex justify-center items-center'>
          <ValidationForm></ValidationForm>
          </div>
        </div>


        
        </Context.Provider>

    </div>
  );
}

export default App;
