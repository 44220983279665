import React from 'react'
import ButtonSet from './ButtonSet'

function AgreeSet({tag, content}) {
  return (
    <div className='flex flex-col items-center'>
        <p className='bg-slate-600 p-4 rounded-lg text-center'>
            <div dangerouslySetInnerHTML={{__html: content}}>

            </div>
        </p>
        <ButtonSet tag = {tag}></ButtonSet>

    </div>
  )
}

export default AgreeSet