import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import ButtonSet from './ButtonSet';
import { Context } from '../App'
import AgreeSet from './AgreeSet';

function ValidationForm() {
    const [getBtags, setBtags] = useContext(Context) // {tag and true or false}
    const [yesAll, setYesAll] = useState(false) // {tag and true or false}

    const handleSubmit = () => {
        console.log('--------------')
        console.log(getBtags)
        console.log(isVerified())
    }

    function handleEnterSite() {
        if (isVerified() === true) {
            window.location.href = "https://nsfw.mare256.com"
        }
    }

    function isVerified() {
        for (const prop in getBtags) {
            const res = getBtags[prop]
            if (res === false) {
                return false
            }
        }
        return true

    }

    function getalltrue() {
        const newmap = {}
        for (const prop in getBtags) {
            newmap[prop] = true
        }
        return newmap
    }

    return (
        <div className=' bg-slate-900 rounded-2xl font-medium p-5 text-white mt-20 w-1/2'>
            <div className='justify-center align-middle flex flex-col'>
                <p className='text-center mb-4 text-2xl text-yellow-100'>            This website is still under construction!
</p>
                <AgreeSet tag = 'b1' content = 'Is your age over 18? <br> It is illegal for you to enter if you are not!'></AgreeSet>
                <AgreeSet tag = 'b2' content = "Are you already familiar with the background lore of my world, so you should already 100% always know that all my designed characters are over 18 in age, consenting, and not engaged in zoophilia?"></AgreeSet>
                <AgreeSet tag = 'b3' content = "Are you aware that this entire website is a work of fiction that is a part of the aforementioned world?"></AgreeSet>

                {/* <ButtonSet tag='b1'></ButtonSet>
                <ButtonSet tag='b2'></ButtonSet>
                <ButtonSet tag='b3'></ButtonSet> */}

                <button type="button" className={`
        p-3 bg-green-600 rounded-lg mb-7 hover:bg-green-500
        
        `} onClick={() => {
                        for (const key in getBtags) {
                            setBtags(getalltrue())
                        }
                        setYesAll(true)

                    }}>{`Yes To All`}</button>
    <div>
    </div>


                <button type="button" className={`
        ${isVerified() ? `bg-green-600 hover:bg-green-500` : `bg-green-950 hover:bg-red-600 hover-`
                    }
        rounded-lg p-8
        `} onClick={() => handleEnterSite()}>Enter Adult Site</button>

<div className='m-4'>
                        {/* {yesAll === true? 'Did you even read it? Not just have the words in your head for a Reddit argument, but actually have your soul comprehend it, if you even have one, you little fucking mongerel? Half of my problems, and all of yours, could be solved before they begin.':''} */}
                        {/* {yesAll === true? <div>`You're gonna be really confused if you didn't read it` </div>:''} */}

                    </div>

            </div>

        </div>
    );
};

export default ValidationForm